/* Registration Page Styles */
.registration-page {
  position: relative;
  height: 100vh;
  background-image: url('https://plus.unsplash.com/premium_photo-1682430553117-f1d0edb35a14?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Dark overlay */
  z-index: 1;
}

/* Registration Container */
.registration-container {
  position: relative;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 500px;
  text-align: center;
  animation: fadeIn 1s ease-in-out;
}

/* Form Styling */
.form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-title {
  font-size: 28px;
  margin-bottom: 20px;
  color: #333;
  font-family: 'Arial', sans-serif;
}

.registration-form label {
  font-size: 16px;
  color: #555;
  margin-bottom: 10px;
  text-align: left;
  width: 100%;
}

.registration-form input {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.submit-button {
  background-color: #0073b1;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #005a89;
}

/* Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
