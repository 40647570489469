/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.navbar {
  background-color: #004466;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 50px;
  position: sticky;
  top: 0;
  z-index: 999;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.navbar-logo h1 {
  color: #ffffff;
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 1.5px;
  transition: color 0.3s;
}

.navbar-logo h1 a {
  color: inherit;
  text-decoration: none;
}

.navbar-links {
  list-style: none;
  display: flex;
  gap: 15px;
}

.navbar-links li a {
  color: #ffffff;
  text-wrap: nowrap;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  padding: 8px 16px;
  transition: background-color 0.3s ease, transform 0.2s;
  border-radius: 5px;
}

.navbar-links li a:hover,
.navbar-links li a:focus {
  background-color: #006699;
  color: #f1f1f1;
  transform: translateY(-2px);
}

.navbar-profile {
  display: flex;
  align-items: center;
}

.navbar-profile .profile-link {
  color: #ffffff;
  font-weight: 500;
  font-size: 18px;
  text-decoration: none;
  border: 2px solid #ffffff;
  padding: 8px 18px;
  border-radius: 25px;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
}

.navbar-profile .profile-link:hover {
  background-color: #ffffff;
  color: #004466;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}

/* Ant Design button styling for Login/Register */
.ant-btn-primary {
  background-color: #006699;
  border-color: #006699;
  font-weight: 500;
  border-radius: 20px;
  padding: 8px 20px;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: #004466;
  border-color: #004466;
  color: #ffffff;
}

/* Responsive Navbar for Mobile */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 20px;
    position: relative;
  }

  .navbar-logo h1 {
    margin-bottom: 15px;
  }

  .navbar-links {
    flex-direction: column;
    width: 100%;
    text-align: left;
    gap: 15px;
    padding: 10px 0;
    border-top: 1px solid #ffffff33;
    border-bottom: 1px solid #ffffff33;
  }

  .navbar-profile {
    margin-top: 15px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .navbar-links li a {
    padding: 10px 20px;
  }
}

/* Style for the primary button in the navbar */
.navbar .ant-btn-primary {
  background-color: #0073b1;
  /* Primary color */
  color: #fff;
  border: none;
  font-size: 16px;
  padding: 8px 16px;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Hover effect for the primary button */
.navbar .ant-btn-primary:hover {
  background-color: #005a89;
  /* Darker shade for hover */
  transform: scale(1.05);
  /* Slightly increase size on hover */
}

/* Active state */
.navbar .ant-btn-primary:active {
  background-color: #00446d;
  /* Even darker shade for active */
}

/* Dropdown trigger styling */
.navbar .ant-dropdown-trigger {
  display: inline-flex;
  align-items: center;
}

/* Adjust the button spacing */
.navbar .ant-btn-primary+.ant-dropdown-trigger {
  margin-left: 10px;
}

/* Custom font and padding for the dropdown menu */
.navbar .ant-dropdown-menu {
  font-size: 14px;
  min-width: 120px;
}

/* Custom style for the dropdown menu items */
.navbar .ant-dropdown-menu-item {
  padding: 10px 20px;
}

.navbar .ant-dropdown-menu-item:hover {
  background-color: #f5f5f5;
  color: #0073b1;
}