/* ChatLayout CSS */
.ant-layout-sider {
  transition: width 0.3s ease;
}

.ant-layout-sider:hover {
  width: 320px;
}

.ant-list-item {
  transition: background-color 0.3s, transform 0.3s;
}

.ant-list-item:hover {
  transform: translateX(10px);
  background-color: #f0f5ff;
}

.ant-avatar {
  transition: background-color 0.3s;
}

/* Active User List */
.ant-list-item:hover .ant-avatar {
  background-color: #0066cc;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .ant-layout-sider {
    width: 100%;
    position: relative;
  }

  .ant-layout-content {
    margin-top: 16px;
  }
}