/* Profile Container */
.profile-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}

/* Profile Info Section */
.profile-info {
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Profile Picture */
.profile-picture img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 4px solid #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  margin-right: 20px;
  object-fit: cover;
}

/* Profile Details */
.profile-details {
  text-align: left;
}

.profile-details h1 {
  font-size: 28px;
  margin-bottom: 5px;
  color: #333;
}

.profile-details h2 {
  font-size: 18px;
  color: #555;
  margin-bottom: 10px;
}

.profile-details p {
  font-size: 16px;
  color: #777;
  margin: 2px 0;
}

/* Bio Section */
.bio-section {
  margin-top: 30px;
  padding: 20px;
  background-color: #fafafa;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.bio-section h2 {
  font-size: 22px;
  color: #333;
  margin-bottom: 10px;
}

.bio-section p {
  font-size: 16px;
  color: #666;
  line-height: 1.6;
}

/* Skills Section */
.skills-section {
  margin-top: 30px;
  padding: 20px;
  background-color: #fafafa;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.skills-section h2 {
  font-size: 22px;
  color: #333;
  margin-bottom: 15px;
}

.skills-section ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.skills-section ul li {
  background-color: #0073b1;
  color: white;
  padding: 8px 12px;
  border-radius: 5px;
  margin: 5px;
  font-size: 14px;
}

/* Experience Section */
.experience-section {
  margin-top: 30px;
  padding: 20px;
  background-color: #fafafa;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.experience-section h2 {
  font-size: 22px;
  color: #333;
  margin-bottom: 15px;
}

.experience-item {
  margin-bottom: 20px;
}

.experience-item h3 {
  font-size: 18px;
  color: #333;
  margin-bottom: 5px;
}

.experience-item p {
  font-size: 16px;
  color: #555;
}

/* Education Section */
.education-section {
  margin-top: 30px;
  padding: 20px;
  background-color: #fafafa;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.education-section h2 {
  font-size: 22px;
  color: #333;
  margin-bottom: 15px;
}

.education-item h3 {
  font-size: 18px;
  color: #333;
  margin-bottom: 5px;
}

.education-item p {
  font-size: 16px;
  color: #555;
}