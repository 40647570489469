.connect-container {
    padding: 20px;
    background: linear-gradient(to bottom right, #d8d6d6, #a7cff7);
    min-height: 80vh; 
  }
  
  .connect-title {
    font-size: 32px;
    color: #3f8efc;
    text-align: center;
    margin-bottom: 20px; /* Reduced margin */
  }
  
  .discussion-groups, .public-forums, .cta-section {
    margin-bottom: 30px; /* Reduced margin for sections */
    text-align: center; /* Center align all sections */
  }
  
  .discussion-groups, .public-forums, .cta-section h2 {
    font-size: 24px; /* Slightly smaller for better fit */
    color: #495057;
    margin-bottom: 15px;
  }
  
  .discussion-groups, .public-forums, .cta-section p {
    font-size: 18px;
    color: #495057;
  }
  
  .group-list, .forum-list {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center; /* Align list items horizontally */
    flex-wrap: wrap; /* Wrap items for better layout */
    gap: 15px; /* Add space between items */
  }
  
  .group-list li, .forum-list li {
    font-size: 18px;
    padding: 10px 20px; /* Added padding to links */
    background: #f0f8ff; /* Background color for better visual appeal */
    border-radius: 5px; /* Rounded corners */
    margin-bottom: 10px;
  }
  
  .group-list li a, .forum-list li a {
    color: #3f8efc;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .group-list li a:hover, .forum-list li a:hover {
    color: #ff4757;
  }
  
  .cta-section {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .cta-button {
    background-color: #3f8efc;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 18px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #ff4757;
  }
  
  @media screen and (max-width: 768px) {
    .events-container {
      flex-direction: column;
    }
  
    .event-card {
      width: 100%;
    }
    
  }