.career-container {
    padding: 20px;
    background: linear-gradient(to bottom right, #d8d6d6, #a7cff7);
    min-height: 80vh;
  }
  
  .career-title {
    font-size: 32px;
    color: #3f8efc;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .career-section {
    margin-bottom: 30px;
    text-align: center;
  }
  
  .career-section h2 {
    font-size: 24px;
    color: #495057;
    margin-bottom: 15px;
  }
  
  .career-section p {
    font-size: 18px;
    color: #495057;
  }
  
  .career-list {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
  }
  
  .career-list li {
    font-size: 18px;
    padding: 10px 20px;
    background: #f0f8ff;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .career-list li a {
    color: #3f8efc;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .career-list li a:hover {
    color: #ff4757;
  }
  
  .cta-button {
    background-color: #3f8efc;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 18px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 15px;
  }
  
  .cta-button:hover {
    background-color: #ff4757;
  }
  
  @media screen and (max-width: 468px) {
    .events-container {
      flex-direction: column;
    }
  
    .event-card {
      width: 100%;
    }
    
  }