.job-container {
    padding: 20px;
    background: linear-gradient(to bottom right, #d8d6d6, #a7cff7);
    min-height: 80vh;
  }
  
  .job-title {
    font-size: 32px;
    color: #3f8efc;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .filter-section {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  #jobFilter {
    padding: 10px;
    font-size: 16px;
    margin-left: 10px;
  }
  
  .job-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
  }
  
  .job-card {
    background-color: #f0f8ff;
    border: 1px solid #dfe6e9;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    transition: transform 0.3s ease;
  }
  
  .job-card:hover {
    transform: scale(1.05);
  }
  
  .job-card h2 {
    color: #3f8efc;
    margin-bottom: 10px;
  }
  
  .job-card p {
    margin-bottom: 8px;
    color: #495057;
  }
  
  .apply-button {
    background-color: #3f8efc;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .apply-button:hover {
    background-color: #ff4757;
  }
  
  @media screen and (max-width: 768px) {
    .events-container {
      flex-direction: column;
    }
  
    .event-card {
      width: 100%;
    }
    
  }