.account-settings-container {
    padding: 40px;
    max-width: 800px;
    margin: 0 auto;
    background: linear-gradient(to bottom right, #d8d6d6, #a7cff7);
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  
  .section-title {
    font-size: 36px;
    text-align: center;
    margin-bottom: 30px;
    color: #2c3e50;
  }
  
  .profile-section, .password-section, .notifications-section {
    margin-bottom: 40px;
  }
  
  h3 {
    font-size: 28px;
    color: #34495e;
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 15px;
    font-size: 18px;
    color: #555;
  }
  
  input[type="text"], input[type="email"], input[type="password"] {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-top: 5px;
    font-size: 16px;
  }
  
  input[type="checkbox"] {
    margin-right: 10px;
  }
  
  .save-button {
    background-color: #3498db;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 18px;
    width: 100%;
    transition: background-color 0.3s ease;
  }
  
  .save-button:hover {
    background-color: #2980b9;
  }
  
  .save-button:focus {
    outline: none;
  }
  
  @media screen and (max-width: 768px) {
    .events-container {
      flex-direction: column;
    }
  
    .event-card {
      width: 100%;
    }
    
  }